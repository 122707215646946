<template>
  <div class="text-center">
    <v-snackbar
      :color="$blue"
      v-model="snackbar"
      :timeout="timeout"
    >
      <template v-if="error != null">
        {{ error.msg[locale] }}  
      </template>
      <v-btn
        color="red"
        text
        @click="clearError()"
      >
        {{ locale == 'fi' ? 'Sulje' : 'Close'}}
      </v-btn>
    </v-snackbar>
  </div>
</template>

<script>
export default {
  name: "Error",
  data() {
    return {
      snackbar: false,
      timeout: 5000
    };
  },
  methods: {
    clearError() {
      this.$store.commit("clearError");
    }
  },
  watch: {
    error(val) {
      if (val != null) {
        this.snackbar = true;
      } else {
        this.snackbar = false;
      }
    }
  },
  computed: {
    locale() {
      return this.$store.state.locale;
    },
    error() {
      return this.$store.state.errorMsg;
    }
  }
};
</script>